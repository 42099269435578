/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    blockquote: "blockquote",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/hogar1.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 98.73417721518987%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMUlEQVQ4y6WU20sUcRTHz29mZ39zkZJ07SF6iIiKHlLQwAxLsqAg19yLu+4aRUUPBd1I06eglwgiKHrqQuVDV7rS7aFeNM2skP6hb5yzO+vuqDMrPRxmGM7vM+f7/Z1zKE0OBsn978iRiwFyQHlyUQyJIXJRKD/D8obLYMqVPxQCUZQED+cbbYy1aJxbbVeghWXyswzMhwBz5OHu9hiedBp40GYiRd6SsLqBXNHtrRa+HiB8TxJGmjSOmi6G1QqBvtzxFo2zq2w8bDfxN0943mXg5mYLx2LuIvmhQK4gTR6urNMCeN+j8CtDeLPbwP02EyfijthRXKnkF10G3vUoTPURZgcIH/YqvO42cHGNXcmJBPpyLzXZAmEY+8fx4zBhup/wqVfh6vo4MuRV/FwW6Mu9tiGOuVQt0I+5NGG0WSNbJTsSeGOTJQeDwMm+kpdnGuwaH0Ml58nD4w5zSSDHnW0xnPYcVJ8NrZD/PL5WC2w6AJtKEmb6CaMJLV5HAjmJvTmpHbzqNmouhp8/U4SJHabYMhRVISdcaLTxqN0UAN/oTDn43a/wy34lHh+3nJqlsQjIi+LzPoU/WcKznYZAPvYq6b9vBwmTh0h6815rTFrocmLhpivA6m3DcG6HkWYtB39nSLbNy10G3u5RuL7RwvwgYSyhxesjKqKxSx9LHk50mDJ2SWrA005DKuWcW1ssmZRMvaO3IL9kOgfP7im75BeDgnNc17YJbm0/j6UWQ/bhP7YqakL0zzOAAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"icono_hogar\"\n        title=\"icono_hogar\"\n        src=\"/static/567fd57fbe534be8a86300f9d2c9b370/5a46d/hogar1-300x297.png\"\n        srcset=\"/static/567fd57fbe534be8a86300f9d2c9b370/c26ae/hogar1-300x297.png 158w,\n/static/567fd57fbe534be8a86300f9d2c9b370/5a46d/hogar1-300x297.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Como ya ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/tag/hogar/"
  }, "hemos visto anteriormente"), " podemos encontrar hoy en día hogares de distintos tipos, lo que era el hogar de la familia nuclear se ha fragmentado en distintos tipos y además han aparecido nuevos tipos de hogares no familiares, todo un reto de clasificación, pero que además ofrece nuevos nichos de mercado donde el clásico electrodoméstico no puede llegar, precisamente por ", React.createElement(_components.a, {
    href: "http://lasindias.com/entender-no-es-un-derecho-es-una-responsabilidad/"
  }, "su desconexión con la realidad"), ". Hoy en día podemos empezar a crear servicios y productos para esos hogares pues, ", React.createElement(_components.a, {
    href: "http://lasindias.com/moviles-casas-y-coches/"
  }, "como escribe hoy David"), ":"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Qué hacer y ", React.createElement(_components.a, {
    href: "http://lasindias.org/el-modo-de-produccion-p2p"
  }, "qué producir"), " pueden ser pensados, cada vez más, desde nuestro ", React.createElement(_components.a, {
    href: "http://lasindias.com/una-vida-interesante/"
  }, "cómo vivir"), "."), "\n"), "\n", React.createElement(_components.p, null, "Para eso voy a realizar una pequeña clasificación de distintos tipos de hogares, aunque cada hogar es un mundo y habrá siempre tendencia a la hibridación esta clasificación nos puede ayudar a empezar a ofrecer productos para ellos."), "\n", React.createElement(_components.p, null, "La clasificación principal divide estos hogares en familiares y comunitarios, los primeros se forman por familias que comparten vivienda, el hogar tradicional, el segundo por comunidades que comparten una identidad y pueden ser familia o no, pero se ayudan mutuamente para sostener su ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2013/02/02/el-mito-del-hogar-ii/"
  }, "zona de confort"), "."), "\n", React.createElement(_components.p, null, "Hogares familiares:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Hogar tradicional: Patriarcal y orientado al mundo agrario, incluye a familias enteras con varias generaciones, el que menos nos interesa."), "\n", React.createElement(_components.li, null, "Hogar industrial: El hogar de ", React.createElement(_components.a, {
    href: "http://lasindias.com/el-modelo-familiar-del-futuro/"
  }, "la familia nuclear de finales de s. XX, más democrático que el anterior pero poco cuajado"), ", el hogar “tipo” a día de hoy."), "\n", React.createElement(_components.li, null, "Hogar franquicia: El que realmente ha tenido éxito antes y durante la crisis aunque ahora mismo este modelo esté en peligro, ", React.createElement(_components.a, {
    href: "http://colabores.net/2013/01/10/que-hogares-para-que-familias-soluciones-para-el-caos/"
  }, "se pueden buscar soluciones que lo mantengan"), "."), "\n"), "\n", React.createElement(_components.p, null, "Hogares comunitarios:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Hogar taller: La llegada de Internet, impresoras 3D, ", React.createElement(_components.a, {
    href: "http://openwetware.org/wiki/Main_Page"
  }, "laboratorios biológicos DIY"), ", huertos urbanos, etc. ha puesto en relevancia un nuevo tipo de hogar, el que tiene incrustado el mismo taller donde ", React.createElement(_components.a, {
    href: "http://lasindias.org/el-modo-de-produccion-p2p/"
  }, "se produce"), ", estos hogares no son sólo herederos de los garajes estadounidenses de los 80, son todo lo que queda del taller-hogar gremial típico de la edad media."), "\n", React.createElement(_components.li, null, "Hogar nómada: Ya hemos tocado el tema del móvil y el coche, es cierto que podemos desprendernos de la idea de una vivienda fija que no podremos obtener y aprender a sobrevivir en la jungla urbana, sólo tenemos que desarrollar esas herramientas."), "\n"), "\n", React.createElement(_components.p, null, "Todos estos tipos de hogares y más que se me olvidan conforman un solo hogar multidimensional que pertenece más o menos a cada uno de estos vectores, no existe un hogar puro y por ello no hay una única forma de acercarse a este mercado, pero podemos poner a su disposición las herramientas con las que ellos evolucionen hacia donde quieran eliminando las limitaciones que los anclan al hogar industrial."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
